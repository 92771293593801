import {AssertionError} from 'assert';

import {Alert, Link} from '@code-like-a-carpenter/components';
import {isRouteErrorResponse, useRouteError} from '@remix-run/react';

import {GithubRequestError} from '@check-run-reporter/client-github';

export const ReusableErrorBoundary = () => {
  const error = useRouteError();

  if (isRouteErrorResponse(error)) {
    if (error.status === 402) {
      try {
        const {installationUrl} = JSON.parse(error.data);
        return (
          <Alert>
            <Alert.Heading>{error.statusText}</Alert.Heading>
            <p>
              You can upgrade your account through the{' '}
              <Link href={installationUrl}>GitHub Settings UI</Link>.
            </p>
          </Alert>
        );
      } catch (err) {
        return (
          <Alert>
            <Alert.Heading>{error.statusText}</Alert.Heading>
            <p>You can upgrade your account through the GitHub Settings UI.</p>
          </Alert>
        );
      }
    }
  }

  // eslint-disable-next-line no-console
  console.error(error);

  if (error instanceof GithubRequestError) {
    return <>{error.toString()}</>;
  }
  if (error instanceof AssertionError) {
    return <>{error.toString()}</>;
  }
  if (error instanceof Error) {
    return <>{error.toString()}</>;
  }

  return (
    <p>
      An unexpected error occurred. Please see the console for more details.
    </p>
  );
};
