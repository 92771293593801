import {env} from '@code-like-a-carpenter/env';
import {App} from '@octokit/app';
import {createAppAuth} from '@octokit/auth-app';
import {graphql} from '@octokit/graphql';
import {retry} from '@octokit/plugin-retry';
import {throttling} from '@octokit/plugin-throttling';
import {Octokit as BaseOctokit} from '@octokit/rest';

import type {Context} from '@check-run-reporter/interact';

const Octokit = BaseOctokit.plugin(throttling).plugin(retry);

export async function getApp({logger}: Context) {
  return new App({
    Octokit: Octokit.defaults({
      throttle: {
        id: 'check-run-reporter',
        onRateLimit(
          retryAfter: number,
          options: {method: string; url: string}
        ) {
          logger.warn('Rate limit detected against github api', {
            method: options.method,
            retryAfter,
            url: options.url,
          });
          return true;
        },
        onSecondaryRateLimit(
          retryAfter: number,
          options: {method: string; url: string}
        ) {
          logger.warn('Abuse detected against github api', {
            method: options.method,
            retryAfter,
            url: options.url,
          });
          return true;
        },
      },
    }),
    appId: env('GITHUB_APP_ID'),
    log: logger,
    oauth: {
      clientId: env('GITHUB_APP_CLIENT_ID'),
      clientSecret: env('GITHUB_APP_CLIENT_SECRET'),
    },
    privateKey: env('GITHUB_APP_KEY'),
  });
}

export async function getClientForApp(context: Context): Promise<BaseOctokit> {
  return (await getApp(context)).octokit;
}

export async function getClientForInstallation(
  installationId: number,
  context: Context
) {
  return (await getApp(context)).getInstallationOctokit(installationId);
}

export async function getClientForRepository(
  organization: string,
  repository: string,
  context: Context
): Promise<BaseOctokit> {
  const appClient = await getClientForApp(context);
  const {data: installation} = await appClient.apps.getRepoInstallation({
    owner: organization,
    repo: repository,
  });

  return (await getApp(context)).getInstallationOctokit(installation.id);
}

export async function getClientForUser(
  context: Context,
  token: string
): Promise<BaseOctokit> {
  return (await getApp(context)).oauth.getUserOctokit({
    token,
  }) as unknown as BaseOctokit;
}

export function getGraphqlClientForInstallation(installationId: number) {
  const auth = createAppAuth({
    appId: Number(env('GITHUB_APP_ID')),
    installationId,
    privateKey: env('GITHUB_APP_KEY'),
  });

  const client = graphql.defaults({
    request: {
      hook: auth.hook,
    },
  });
  return client;
}

export function getGraphqlClientForUser(accessToken: string) {
  return graphql.defaults({
    headers: {
      authorization: `token ${accessToken}`,
    },
  });
}
