import type {Octokit} from '@octokit/rest';
import chunk from 'lodash/chunk';

import type {Context} from '@check-run-reporter/interact';

import type {CheckRun, CheckRunAnnotation} from './types';

export interface AnnotateInput {
  readonly annotations: readonly CheckRunAnnotation[];
  readonly summary: string;
  readonly title: string;
  readonly check: Pick<CheckRun, 'id'>;
  readonly client: Octokit;
  readonly owner: string;
  readonly repo: string;
}

export async function annotate(
  {annotations, client, check, owner, repo, summary, title}: AnnotateInput,
  {logger}: Context
) {
  const chunks = chunk(annotations, 50);
  logger.info('Chunked report', {chunkCount: chunks.length});

  for (const [index, c] of chunks.entries()) {
    logger.info('sending report chunk with annotations', {
      chunk: index,
      total: chunks.length,
    });

    await client.checks.update({
      check_run_id: check.id,
      output: {
        annotations: c,
        summary,
        title,
      },
      owner,
      repo,
      status: 'in_progress',
    });

    logger.info('sent report chunk with annotations', {
      chunk: index,
      total: chunks.length,
    });
  }
}
